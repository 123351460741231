import React from "react";
import { Box } from "../../core/box";
import { Grid } from "../../core/Grid/Grid";
import { Typography } from "../../core/Typography/Typography";
import { Section, SectionProps } from "../Section/Section";

export interface SplitContentSectionProps extends SectionProps {
  heading: string;
  text: string;
}

export function SplitContentSection(props: SplitContentSectionProps) {
  const { theme, heading, text } = props;

  return (
    <Section theme={theme}>
      <Grid>
        <Typography
          gridColumn={["1 / span 12", "1 / span 12", "1 / span 6"]}
          variant="h2"
          fontSize={[6, 7]}
          paddingRightSpacing={[0, 0, 5]}
        >
          {heading}
        </Typography>
        <Box gridColumn={["1 / span 12", "1 / span 12", "7 / span 6"]} paddingTop={[6, 6, 0]}>
          <Typography variant="body1" fontSize={[1, 2]}>
            {text}
          </Typography>
        </Box>
      </Grid>
    </Section>
  );
}
