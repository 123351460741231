import React from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "../components/layout";
import { LeadershipSection } from "../components/rebrand/sections/Leadership/LeadershipSection";
import { LocationsMapSection } from "../components/rebrand/sections/LocationsMap/LocationsMapSection";
import { PageHeaderSection } from "../components/rebrand/sections/PageHeader/PageHeaderSection";
import { SplitContentSection } from "../components/rebrand/sections/SplitContent/SplitContent";
import { SEO } from "../components/seo";
import { AboutPageData } from "../data/about";

const { initialNavigationTheme, pageTitleData, splitContentData, leadershipData, locationsMapData } =
  AboutPageData;

interface AboutPageProps extends PageProps {
  data: Queries.AboutPageQuery;
}

export const pageQuery = graphql`
  query AboutPage {
    site {
      siteMetadata {
        pages {
          about {
            title
            description
            keywords
          }
        }
      }
    }
  }
`;


export default function AboutPage(props: AboutPageProps) {
  
  const {
    location: { pathname },
    data:  { site }
  } = props;

  const { title, description, keywords } = site?.siteMetadata?.pages?.about as Queries.SiteSiteMetadataPagesAbout;

  const seo = {
    title: title as string,
    description: description as string,
    // keywords: keywords as [],
    pathname,
  };

  return (
    <Layout initialNavigationTheme={initialNavigationTheme}>
      <SEO {...seo} />
      <PageHeaderSection theme={pageTitleData.theme} title={pageTitleData.title} cta={pageTitleData.cta} />
      <SplitContentSection
        theme={splitContentData.theme}
        heading={splitContentData.heading}
        text={splitContentData.text}
      />
      {/* <LeadershipSection theme={leadershipData.theme} title={leadershipData.title} />
      <LocationsMapSection
        theme={locationsMapData.theme}
        title={locationsMapData.title}
        subtitle={locationsMapData.subtitle}
      /> */}
    </Layout>
  );

}
