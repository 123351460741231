import { InvestorsSectionProps } from "../components/rebrand/sections/Investors/InvestorsSection";
import { LeadershipSectionProps } from "../components/rebrand/sections/Leadership/LeadershipSection";
import { LocationsMapSectionProps } from "../components/rebrand/sections/LocationsMap/LocationsMapSection";
import { PageHeaderSectionProps } from "../components/rebrand/sections/PageHeader/PageHeaderSection";
import { SplitContentSectionProps } from "../components/rebrand/sections/SplitContent/SplitContent";
import { AppThemeVariant } from "../types";
import { links } from "./site";

const initialNavigationTheme: AppThemeVariant = "dark";

const pageTitleData: PageHeaderSectionProps = {
  theme: "dark",
  title: "Nosotros",
  cta: {
    primary: {
      ...links.careers,
      label: `¿Deseas trabajar con nosotros?`,
    },
  },
};

const splitContentData: SplitContentSectionProps = {
  theme: "light",
  heading: "Creamos soluciones para un mundo versátil a través de la tecnología e innovación, ofreciendo un servicio personalizado y de calidad",
  text: "- Asesoramos en todas las etapas de construcción y puesta en marcha de cada proyecto, con el fin de proporcionar un servicio de calidad, que de como resultado la mejora continua de nuestros clientes.\n\n- Trabajamos con expertos en electrónica con amplia trayectoria en automatización y control industrial, informáticos con conocimientos de conectividad e infraestructura y desarrolladores de soluciones en plataformas web y móviles.\n\n- Contamos con un equipo técnico y de soporte calificado dispuesto a resolver todas sus dudas respecto a cuestiones técnicas que puedan surgir.",
};

const leadershipData: LeadershipSectionProps = {
  theme: "light",
  title: "Equipo",
};

const locationsMapData: LocationsMapSectionProps = {
  theme: "light",
  title: "Ubicaciones",
  subtitle:
    "Equipo distribuído",
};

export const AboutPageData = {
  initialNavigationTheme,
  pageTitleData,
  splitContentData,
  leadershipData,
  locationsMapData
};
